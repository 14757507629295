body { 
    background-color: rgb(25, 19, 69);
}

.homePage {
    margin-top: 30px;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
}

.homePage img {
    height: 400px;
}

.homePage h3{
    color: white;
    font-size: 30px;
}

.homePage p {
    color: white;
    font-size: 20px;
    inline-size: 500px;
    overflow-wrap: break-word;
}

@media (max-width: 768px) {
    .homePage {
      flex-direction: column;
      justify-content: space-around;
      align-items: center;
    }
    
    .homePage img {
      height: auto;
      display: flex;
      width: 350px;
    }

    .homePage h3 {
      font-size: 25px;
    }
  
    .homePage p {
      inline-size: auto;
      overflow-wrap: break-word;
      font-size: 15px;
    }
  }