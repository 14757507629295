.faq {
  background-image: linear-gradient(
      to bottom,
      rgba(0, 64, 0, 0.6),
      rgba(0, 65, 0, 0.3)
    ),
    url("../images/faq.jpg");
  background-position: center; /* Center the image */
  background-repeat: no-repeat; /* Do not repeat the image */
  background-size: cover; /* Resize the background image to cover the entire container */
  color: white;
  padding: 20px;
  text-align: center;
}

.faq h1 {
    font-size: 60px;
}

.questionContainer {
  display: flex;
  justify-content: center;
  flex-direction: row;
  flex-wrap: wrap;
  padding: 20px;
  background-color: rgb(0, 0, 0, 0.4);
}

.questionContainer h3 {
  font-size: 30px;
  margin-bottom: 30px;
}

.questionContainer p {
  font-size: 20px;
}

.question {
  width: 600px;
  flex-wrap: wrap;
  padding: 20px;
}

/* Media Query for screens smaller than 768px */
@media only screen and (max-width: 767px) {
  .faq h1 {
    font-size: 40px;
  }
  
  .questionContainer h3 {
    font-size: 24px;
  }
  
  .questionContainer p {
    font-size: 16px;
  }
}
