.dropTitle {
    text-align: center;
    color: white;
    font-size: 50px;
    padding: 30px;
  }
  

.dropContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  color: white;
  padding: 20px;
}

.dropContainer h2 {
    font-size: 40px;
}

.location {
  box-shadow: 2px 2px black;
  background-size: "cover";
  width: 500px;
  padding: 20px;
  background-position: center;
}

.location h3 {
  font-size: 22px;
}

.location p {
  font-size: 18px;
}

@media (max-width: 768px) {

  .dropTitle {
    font-size: 30px;
    padding: 20px;
  }
  
  .dropContainer {
    flex-direction: column;
    align-items: center;
  }
  
  .dropContainer h2 {
    font-size: 30px;
    margin-top: 30px;
  }
  
  .location {
    margin-top: 40px;
    width: 400px;
  }
  
  .location h3 {
    font-size: 18px;
    margin-bottom: 10px;
  }
  
  .location p {
    font-size: 16px;
  }
}