.autoContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    flex-wrap: wrap;
    color: white;
    padding: 20px;
}

.autoContainer h1 {
    font-size: 60px;
    justify-content: left;
}

.autoContainer p {
    font-size: 30px;
    width: 1000px;
}

.inlineLink:hover {
    color: rgb(92, 92, 235);
}


@media (max-width: 768px) {

    .autoContainer {
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    .autoContainer h1 {
        font-size: 40px;

    }

    .autoContainer p {
        font-size: 20px;
        width: auto;
        text-align: left;
    }

    .autoContainer img {
        width: 350px;
        height: auto;
    }
}