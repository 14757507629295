.publishRow {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    padding: 20px;
}


.bookInfo {
    display: flex;
    flex-direction: column;
    width: 700px;
    color: white;
}

.bookInfo h2 {
    font-size: 40px;
}

.bookInfo h3 {
  border: 2px white dotted;
  padding: 10px;
  text-align: center;

}

.bookInfo h3:hover {
  background-color: rgb(81, 174, 255);
  color: white;

}

.bookInfo p {
    width: fit-content;
    flex-wrap: wrap;
    font-size: 20px;
}

.bookInfo li {
    list-style: none;
    width: fit-content;
    border-bottom: 2px white solid;
}

.bookInfo img {
    width: fit-content;
}

.marketplaces {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  
  .marketplaces > * {
    margin-bottom: 10px;
  }
  
  @media (max-width: 768px) {

    .publishRow {
      flex-direction: column;
      justify-content: center;
    }
  
    .bookInfo {
      width: 100%;
      padding: 0;
    }
  
    .bookInfo h2 {
      font-size: 30px;
    }

    .bookInfo img {
        width: 400px;
        padding: 20px;
        align-self: center;
    }
  
    .bookInfo p {
      font-size: 16px;
    }
  
    .bookInfo li {
      border-bottom: none;
    }
  
    .marketplaces {
      align-items: flex-start center;
    }
  
  }