.digiContainer {
    background-color: rgba(21, 74, 35);
    
    width: 100%;
    height: 100%;
    margin-top: 20px;
}

.hero { 
  background-image: linear-gradient(to bottom, rgba(0, 64, 0, 0.6), rgba(0, 65, 0, 0.6)), url("../images/digiHero.avif");
  height: 550px; /* You must set a specified height */
  background-position: center; /* Center the image */
  background-repeat: no-repeat; /* Do not repeat the image */
  background-size: cover; /* Resize the background image to cover the entire container */
  color: white;
  padding: 20px;
  width: 100%;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.hero h1 {
  font-size: 60px;
  margin: 10px;
  margin-top: 4rem;
  padding: 20px;
}

.hero p {
  font-size: 24px;
  margin: 10px;
}

.contactBtn {
  background-color: #0099cc;
  color: white;
  font-size: 18px;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  margin: 20px;
  cursor: pointer;
  width: fit-content;
}


.dropOff {
  font-weight: bold;
  font-size: 30px;
  background-color: rgb(37, 37, 37, 0.7);
}

.dropOff p:hover {
  background-color: rgb(81, 174, 255);
  color: white;

}

.dropOff p {
  border: 2px dotted white;
  padding: 20px;
}

/* ProductContainer */

.productImage {
  height: 250px;
}

.productContainer {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  font-size: 24px;
  color: white;
}

.productRow {
  margin: 10px;
}

.products {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.product {
  margin: 20px;
  width: 200px;
}

.products p {
  font-size: 30px;
  padding: 10px;
  margin: 10px;
  text-align: center;
  color: white;
}

.products img {
  width: 100%;
  height: 200px;
  padding: 10px;
  object-fit: cover;
}

.productRow {
  padding: 20px;
  text-align: center;
}

/* Media queries for mobile screens */

@media (max-width: 768px) {

  .hero h1 {
    font-size: 40px;
    margin-top: 2rem;
  }

  .hero p {
    font-size: 18px;
  }

  .productImage {
    height: 150px;
  }

  .product {
    margin: 10px;
    width: 150px;
  }

  .products p {
    font-size: 24px;
    padding: 5px;
    margin: 5px;
  }

  .products img {
    height: 150px;
  }

  .productRow {
    padding: 10px;
  }
}